import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AdCard.scss';
import { priceFormat, dateFormat } from '../../utils/format';
import Link from "../Link";
import ApiImage from '../ApiImage'

class AdCard extends React.Component {
  render () {
    const { data } = this.props;
    if(!data) return;

    let imgPath = null;

    if(data.images.length > 0 && data.images[0].media)
      imgPath = data.images[0].media.thumbnail;

    return (
      <div className={s.root}>
        <div className={s.preview}>
          <Link to={data.slug}>
            <ApiImage path={imgPath} alt={data.title} canDelete={false} />
          </Link>
        </div>
        <div>
          <div className={s.title}>{data.title}</div>
          <div className={s.date}>{dateFormat(data.created)}</div>
          <div className={s.price}>{priceFormat(data.price)}</div>
          <div className={s.more}>
            <Link to={data.slug}>Преглед на обявата</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(AdCard);
