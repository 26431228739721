import React from 'react';
import { imageThumbnailPath } from '../../utils/resovePath';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './ApiImage.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class ApiImage extends React.Component {
  constructor(props) {
    super(props);
    const {path = null, alt = null, canDelete = false} = props;
    this.path = path;
    this.alt = alt;
    this.canDelete = canDelete;
    this.ref = React.createRef();

    this.state = {
      visible: true,
    }
  }

  handleDelete(){
    this.setState({visible: false});
    this.props.handleDelete();
  }

  renderWithDelete(){
    return (
      <div style={{position: 'relative', display: this.state.visible ? 'inline-block' : 'none'}} key={this.path}>
        {this.renderImageOnly()}
        <button
          onClick={() => this.handleDelete()}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'rgba(255,0,0,0.1)',
            color: 'red',
            border: 'solid 1px red',
            borderRadius: '2px',
          }}
        >
          Изтрий
        </button>
      </div>
    );
  };

  renderImageOnly(){
    return <LazyLoadImage
      key={this.path}
      ref={this.ref}
      alt={this.alt}
      style={{display: this.state.visible ? 'inline-block' : 'none'}}
      // height={image.height}
      src={imageThumbnailPath(this.path)} // use normal <img> attributes as props
      // width={image.width}
    />
  };
  render() {
    return this.canDelete ? this.renderWithDelete() : this.renderImageOnly();
  }
}

export default withStyles(s)(ApiImage);
