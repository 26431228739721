/* eslint react/prop-types: 0 */
/* eslint react/no-did-update-set-state: 0 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import bs from '../../components/bootstrap.scss';
import s from './Home.scss';
import { getCategories } from '../../actions/category';
import Advertisement from '../../components/Advertisement/Advertisement';
import AdList from '../../containers/AdList/AdList';
import AdPagination from '../../containers/AdPagination/AdPagination';
import Link from '../../components/Link/Link'
import homeMarkdown from '../../data/static/home.md';

const homeMarkup = () => {
  return {__html: homeMarkdown.html};
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markdown: null,
    };
  }

  componentDidMount() {
    this.props.getCategories();
    fetch(homeMarkdown).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }

  renderCategory(category) {
    return (
      <Link className={cn(s.category, s[category.code.toLowerCase().replace(/\s+/, '-')])} key={category.id} to={category.slug}>
        <span>{category.name}</span>
      </Link>
    );
  }

  renderFilter() {
    const { categories } = this.props;

    return (
      <div>
        { categories.map(category => this.renderCategory(category)) }
      </div>
    );
  }

  renderDescription() {
    return (
      <div className={s.description}>
        <div dangerouslySetInnerHTML={homeMarkup()} />
      </div>
    );
  }

  render() {
    const { context } = this.props;
    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.topContent}>
            <div className={s.categoryContainer}>
              <h3>Изберете категория</h3>
              { this.renderFilter() }
            </div>
            <div className={s.advContainer}>
              <h3>Реклама</h3>
              <Advertisement />
            </div>
          </div>
          <div className={s.middleContent}>
            {this.props.user.data && this.props.user.data.id ? null : <div className={s.message}>За да видите пълната информация по обявите в този сайт. Моля влезте в профила си.</div>}
            <div className={s.title}>
              <h3>Вип обяви</h3>
              <AdPagination context={context} />
            </div>
            <AdList context={this.props.context} />
            <AdPagination context={context} />
            <div className={s.bottomContent}>
              { this.renderDescription() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ categories, user }) => ({
  categories,
  user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getCategories },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(bs, s)(Home));
