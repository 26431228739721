import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import posed, { PoseGroup } from 'react-pose';
import s from './AdList.scss';
import { clearList, clearMedia, getList, clearAll } from "../../actions/ad";
import { getMedia } from "../../actions/media";
import AdCard from '../../components/AdCard/AdCard';

const Item = posed.div({
  enter: { opacity: 1, transition: { duration: 300 } },
  exit: { opacity: 0, transition: { duration: 300 } },
});

class AdList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,
    };

    this.props.clearAll();
  }

  componentDidMount() {
    this.fetch();
    this.preload = false;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.context !== this.props.context || prevProps.pagination.page !== this.props.pagination.page)
      this.fetch();
    if(this.state.preload && (!prevProps.adList.param || prevProps.adList.param['@id'] !== this.props.adList.param['@id']))
      this.setState({preload: false});
  }

  componentWillUnmount() {
    this.setState({preload: false})
  }

  fetch() {
    const { query } = this.props.context;
    const { search } = query;
    const { pagination, adFilter } = this.props;
    this.props.clearMedia();
    this.props.clearList();
    this.props.getList(pagination.page, {...adFilter, query}, pagination.perPage, search);
  }

  static emptySearch() {
    return (
      <div>Няма намерени обяви!</div>
    );
  }

  static renderAd(data) {
    return <AdCard data={data} key={data['@id']} />
  }

  render() {
    const { adList } = this.props;

    if (adList.param === null && adList.data.length <= 0)
      return (
        <div className={s.root}>
          <PoseGroup>
            <Item key={`preload`}>
              <p className={s.preload}>Зареждане...</p>
            </Item>
          </PoseGroup>
          {this.props.children}
        </div>
      );

    if (adList.param !== null && adList.data.length <= 0)
      return (
        <div className={s.root}>
          <PoseGroup>
            <Item key={`preload`}>
              <p className={s.preload}>Няма намерени обяви!</p>
            </Item>
          </PoseGroup>
          {this.props.children}
        </div>
      );

    return (
      <div className={s.root}>
        <PoseGroup>
          <Item key={`list`}>
            <div className={s.adList}>
              {adList.data.map(d => AdList.renderAd(d))}
            </div>
          </Item>
        </PoseGroup>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = ({ adList, media, categories, query, pagination, adFilter, router }) => ({
  adList,
  media,
  categories,
  query,
  pagination,
  adFilter,
  router
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getList, getMedia, clearMedia, clearList, clearAll },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(AdList));
