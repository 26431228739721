export function priceFormat(price) {
  return new Intl.NumberFormat('bg-BG', {
    currency: 'BGN',
    style: 'currency',
  }).format(price/100);
}

export function dateFormat(date) {
  return new Intl.DateTimeFormat('bg-BG', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(new Date(date));
}
