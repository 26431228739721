function imageRelativePath(path) {
  // const subDirs = [
  //   path.substring(0,3),
  //   path.substring(3,6),
  // ];
  return `${path}`;
}

export function imagePath(path) {
  return `api/${imageRelativePath(path)}`;
}

export function imageThumbnailPath(path) {
  return `api/${imageRelativePath(path)}`;
}
