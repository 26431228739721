import React from "react";
import adv1 from './image/adv1.jpg';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from './Advertisement.scss';


class Advertisement extends React.Component {
  getImage(){
    return adv1;
  }

  render() {
    return (
      <div className={s.root}>
        <a href="https://google.com" target="_blank" title="Бате Гошо">
          <img src={this.getImage()} alt="advertising" />
        </a>
      </div>
    );
  }
}

export const getImage = () => {
 return adv1;
}

export default withStyles(s)(Advertisement);
