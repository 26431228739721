/* eslint-disable import/prefer-default-export */

import { AD_MEDIA, AD_MEDIA_RELATED } from '../constants';

export const getMedia = url => async (dispatch, getState, { axios }) => {
  const response = await axios.get(url);
  dispatch({
    type: AD_MEDIA,
    payload: response.data,
  });
};

export const getRelatedMedia = (url, type) => async (dispatch, getState, { axios }) => {
  const response = await axios.get(url);
  dispatch({
    type: {
      type: AD_MEDIA_RELATED,
      subType: type
    },
    payload: response.data,
  });
};
