exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3M9cp{width:1200px;margin:0 auto}@media screen and (max-width:1200px){._3M9cp{width:100%}}._3M9cp ._2WO6N{text-align:center;font-size:2em;color:#81bc58}._3M9cp .nSIfU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:0;margin-top:-30px}@media screen and (max-width:700px){._3M9cp .nSIfU{display:block;margin:0;width:100%}}._3M9cp .nSIfU>div{margin-top:30px;width:calc(20% - 24px)}@media screen and (max-width:1240px){._3M9cp .nSIfU>div{width:calc(25% - 18px)}}@media screen and (max-width:970px){._3M9cp .nSIfU>div{width:calc(33.33333% - 12px)}}@media screen and (max-width:700px){._3M9cp .nSIfU>div{width:100%}}", ""]);

// exports
exports.locals = {
	"root": "_3M9cp",
	"preload": "_2WO6N",
	"adList": "nSIfU"
};