import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { bindActionCreators } from 'redux';
import Link from '../../components/Link/Link';
import s from './AdPagination.scss';

const MAX_PAGES = 5;

const extractPage = (url) => {
  return parseInt(
    url.replace(/.*page=(\d+)/, '$1')
  );
};

class AdPagination extends React.Component {
  render() {
    const { search } = this.props.context.query;
    const searchParam = search ? `?search=${search}` : '';
    const { adList, pagination } = this.props;
    if (!adList.param || !adList.param['hydra:last']) return '';
    const pageFirst = extractPage( adList.param['hydra:first'] );
    const pageLast = extractPage( adList.param['hydra:last'] );
    const page = parseInt(pagination.page, 10);
    let pages = [page];
    let i = 1, it = 1;
    while(it < MAX_PAGES){
      if (
        (page - i) >= pageFirst &&
        (page + i) <= pageLast
      ) {
        pages.push(page - i);
        pages.push(page + i);
        it += 2;
      } else if ((page - i) >= pageFirst) {
        pages.push(page - i);
        it += 1;
      } else if((page + i) <= pageLast) {
        pages.push(page + i);
        it += 1;
      }else{
        break;
      }
      i += 1;
    }

    pages = pages.sort((a, b) => a - b);

    let prevSet = <span className={s.prevSet} />;
    let prevPage = <span className={s.prevPage} />;
    let nextPage = <span className={s.nextPage} />;
    let nextSet = <span className={s.nextSet} />;
    if(page - MAX_PAGES >= pageFirst)
      prevSet = <Link className={s.prevSet} to={`?page=${page - MAX_PAGES}${searchParam}`} />;
    if(page - 1 >= pageFirst)
      prevPage = <Link className={s.prevPage} to={`?page=${page - 1}${searchParam}`} />;
    if(page + 1 <= pageLast)
      nextPage = <Link className={s.nextPage} to={`?page=${page + 1}${searchParam}`} />;
    if(page + MAX_PAGES <= pageLast)
      nextSet = <Link className={s.nextSet} to={`?page=${page + MAX_PAGES}${searchParam}`} />;

    return (
      <div className={s.root}>
        <p>В тази категория има <span className={s.total}>{adList.total}</span> обяви</p>
        <div className={s.controls}>
          {prevSet}
          {prevPage}
          {pages.map(p => (p === page) ? <span className={s.active} key={p}>{p}</span> : <Link to={`?page=${p}${searchParam}`} key={p}>{p}</Link>)}
          {nextPage}
          {nextSet}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ adList, pagination }) => ({
  adList,
  pagination,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(AdPagination));
