exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26OAI{text-align:right;font-size:9px;line-height:9px}._26OAI:before{content:\"\\420\\435\\43A\\43B\\430\\43C\\430\"}._26OAI a img{width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"root": "_26OAI"
};