exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".iapNQ{position:relative;display:inline}.iapNQ button{position:absolute;top:5px;right:5px}", ""]);

// exports
exports.locals = {
	"image-container": "iapNQ"
};